<template>
  <div class="theme-list">
    <v-card
      max-width="600"
      class="mx-auto mt-2 mb-2 d-flex align-stretch flex-column"
    >
      <v-row no-gutters align="center" justify="center" class="py-1">
        <h3 style="word-break: normal">Liste des thèmes du monde</h3>
      </v-row>
    </v-card>
    <div v-if="dataReady">
      <CategoryList
        nextCollection="worldThemeVoteList"
        paramz="setWorldTheme"
        listz="listTheme"
      />
    </div>
  </div>
</template>

<script>
import CategoryList from "@/component/list/ui/Category.vue";

export default {
  components: {
    CategoryList,
  },
  name: "theme-list",
  data() {
    return {
      dataReady: false,
    };
  },
  mounted: async function () {
    await this.$store.dispatch("vote/getList", {
      loading: null,
      typeList: "theme",
      pathList: "theme",
    });
    this.$store.commit("vote/emptyElectedCalculation");
    this.dataReady = true;
  },
};
</script>
