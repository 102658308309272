<template>
  <div class="world-country-theme-list">
    <v-card
      max-width="600"
      class="mx-auto mt-2 mb-1 d-flex align-stretch flex-column"
    >
      <v-row no-gutters align="center" justify="center" class="py-1">
        <h3 style="word-break: normal">
          {{ worldCountryName | capitalize }} : liste des thèmes
        </h3>
      </v-row>
    </v-card>
    <div v-if="dataReady">
      <CategoryList
        :key="$router.fullPath"
        nextCollection="worldCountryThemeVoteList"
        paramz="setWorldCountryTheme"
        listz="listTheme"
      />
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infinite-loading>
  </div>
</template>

<script>
import CategoryList from "@/component/list/ui/Category.vue";
import InfiniteLoading from "vue-infinite-loading";
import Vuex from "vuex";

export default {
  components: {
    InfiniteLoading,
    CategoryList,
  },
  name: "world-country-theme-list",
  props: ["nextCollection"],
  data: () => ({
    zoneName: "",
    dataReady: false,
  }),
  methods: {
    async infiniteHandler($state) {
      await this.$store.dispatch("vote/getList", {
        loading: $state,
        typeList: "theme",
        pathList: "country/" + this.$store.state.list.worldCountry + "/theme",
      });
      this.dataReady = true;
    },
  },
  computed: {
    ...Vuex.mapState("list", ["worldCountryName"]),
  },
  mounted: function () {
    this.$store.getters["list/breadcrumb"];
    this.$store.commit("vote/setQueryLimit", 20);
  },
};
</script>
