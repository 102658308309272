<template>
  <div v-if="calculated">
    <div v-if="Object.keys(list).length > 0">
      <!-- {{Object.keys(list).length}}  -->
      <!-- <v-card
      v-if="list.length == 1 && paramsForRoute['worldZone'] == list[0].id"
      class="mx-auto d-flex flex-column mb-1"
      max-width="600"
    >
      {{ list[0].id }} //
      {{ paramsForRoute["worldZone"] == list[0].id }}
      <v-card-title class="justify-center"
        ><h4>Il n'y a pas de sous-zones</h4></v-card-title
      >
    </v-card> -->
      <v-card
        v-for="(item, index) in list"
        class="mx-auto d-flex flex-column mb-1"
        max-width="600"
        @click="toVote(item)"
        :key="index"
        :disabled="!item.enabled"
      >
        <v-card-title class="justify-center pb-1"
          ><h5 style="word-break: normal;text-align:center">{{ item.name }}</h5></v-card-title
        >
        <v-card-text class="text-center">{{ item.description }}</v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-card class="mx-auto d-flex flex-column mb-1" max-width="600">
        <v-card-title class="justify-center"
          ><h4>Il n'y a pas de liste</h4></v-card-title
        >
      </v-card>
    </div>
  </div>
</template>
<script>
import Vuex from "vuex";

export default {
  name: "CategoryList",
  props: ["nextCollection", "paramz", "listz"],
  data: () => ({
    calculated: false,
  }),
  methods: {
    async toVote(item) {
      if(item.name == "Amérique Latine et Caraïbes") {
        this.$router.push({
          path: "/france",
        });        
      }
      else {
        this.$store.commit("list/" + this.paramz, item.id);
        this.$store.commit("list/" + this.paramz + "Name", item.name);
        // this.$store.commit("list/" + this.paramz + "VoteListLength", item.voteListLength);
  
        if(this.$store.state.list.worldZoneChoice == 'zone') {
          this.$store.commit("list/changeWorldZoneChoice", 'theme');
        }

        this.$router.push({
          name: this.nextCollection,
          params: this.paramsForRoute,
          replace: true,
        });
      }
    },
  },
  mounted: function () {
    this.calculated = true;
    this.$store.commit("vote/setQueryLimit", 20);
  },
  computed: {
    ...Vuex.mapState({
      list(state) {
        return state.vote[this.listz];
      },
    }),
    ...Vuex.mapGetters("list", ["paramsForRoute"]),
  },
};
</script>
