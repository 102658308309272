<template>
  <div>
    <!-- <div v-if="nbTheme < 0" class="world-country-theme-list">
      <div v-if="dataReady"></div> -->
      <!-- <VoteList /> -->
    <!-- </div>
    <div v-else> -->
      <List />
    <!-- </div> -->
  </div>
</template>

<script>
import VoteList from "@/component/vote/voteList/VoteList.vue";
import List from "@/component/list/world/country/theme/List.vue";
import Vuex from "vuex";

export default {
  components: {
    List,
    VoteList,
  },
  name: "world-country-theme-list",
  props: ["nextCollection"],
  data: () => ({
    zoneName: "",
    dataReady: false,
    //nbTheme: []
  }),
  methods: {
    // this.dataReady = true;
  },
  computed: {
    ...Vuex.mapState("list", ["worldCountryName"]),
    nbTheme: function () {
      return this.$store.state.vote.listTheme.length;
    },
  },
  mounted: function () {
    this.$store.getters["list/breadcrumb"];
    this.$store.commit("vote/emptyElectedCalculation");
    // this.nbTheme = this.$store.state.vote.listTheme
  },
};
</script>
